import {
    FacetsBodyType,
    RelewiseRangeType,
    FacetRange,
    SelectedFacet,
} from '$templates/blocks/components/M140ProductsList';

export const hydrateFacetsWithSelectedFacets = (
    facets: FacetsBodyType[],
    selectedFacets: SelectedFacet[]
) =>
    facets.map((filter) => {
        const key = filter.key || filter.field;
        const selectedOption = selectedFacets.find(
            (facet) => facet.attribute.toLowerCase() === key.toLowerCase()
        );

        filter.selected = getSelectedValue(selectedOption, filter.$type);

        return filter;
    });

const getSelectedValue = (selectedOption: SelectedFacet | undefined, type: string) => {
    if (!selectedOption) return [];

    if (!type.includes('PriceRangeFacet')) {
        return selectedOption.items.map((item) => item.value as string | number);
    }

    const selectedRange = {} as RelewiseRangeType;

    const min = (selectedOption.items[0]?.value as FacetRange)?.min;
    const max = (selectedOption.items[0]?.value as FacetRange)?.max;

    if (min !== undefined) {
        selectedRange.lowerBoundInclusive = min;
    }
    if (max !== undefined) {
        selectedRange.upperBoundInclusive = max;
    }

    return selectedRange;
};
