/**
 * Relewise specific Types
 */

import {
    Product,
    ProductCategoryPath,
    SortType,
} from '$templates/blocks/components/M140ProductsList';
import { RelewiseContentResult } from '$templates/blocks/components/SearchResults';

export type BrandType = {
    id: string;
    displayName: string;
};

export type RelewiseResponseFacetsAvailableOption = {
    value: string | boolean | RelewiseRangesType | BrandType;
    hits: number;
    selected: boolean;
};

export type RelewiseResponseFacets = {
    $type: string;
    key?: string;
    collectionFilterType?: string;
    dataSelectionStrategy?: string;
    categorySelectionStrategy?: string;
    field: string;
    selected?: string[] | boolean[] | RelewiseRangesType[] | BrandType[];
    available:
        | RelewiseResponseFacetsAvailableOption[]
        | {
              value: RelewiseRangeType;
              hits: number;
              selected: boolean;
          };
};

export enum SearchResultsType {
    PRODUCT_SEARCH = 'Relewise.Client.Responses.Search.ProductSearchResponse, Relewise.Client',
    CONTENT_SEARCH = 'Relewise.Client.Responses.Search.ContentSearchResponse, Relewise.Client',
}

export type SearchRequestCollectionResponseType = {
    responses: (ContentSearchRequestResponseType | ProductSearchRequestResponseType)[];
};

export type SearchRequestResponseType = {
    nextCursor?: unknown;
    facets?: {
        items: RelewiseResponseFacets[];
    };
    recommendations: unknown[];
    hits: number;
    statistics: {
        serverTimeInMs: number;
    };
};

export interface ContentSearchRequestResponseType extends SearchRequestResponseType {
    results: RelewiseContentResult[];
    $type: SearchResultsType.CONTENT_SEARCH;
}
export interface ProductSearchRequestResponseType extends SearchRequestResponseType {
    results: Product[];
    $type: SearchResultsType.PRODUCT_SEARCH;
}
export interface PredictionResultItem {
    term: string;
    rank: number;
    type: string;
    expectedResultTypes: [
        {
            estimatedHits: number;
            type: 'Content' | 'Product';
        }
    ];
}
export interface PredictionSearchRequestResponseType {
    predictions: PredictionResultItem[];
}

export type ProductCategoryBaseItem = {
    categoryId: string;
    displayName: {
        values: {
            language: {
                value: string;
            };
            text: string;
        }[];
    };
    disabled: boolean;
};

export type ProductCategoryQueryItem = ProductCategoryBaseItem & {
    $type: string;
    rank: number;
    paths: ProductCategoryPath[];
    assortments: number[];
    parentCategories: ProductCategoryQueryItem[];
    childCategories: ProductCategoryQueryItem[];
    data: {
        [key: string]: {
            type:
                | 'String'
                | 'Double'
                | 'Boolean'
                | 'Multilingual'
                | 'Money'
                | 'MultiCurrency'
                | 'StringList'
                | 'DoubleList'
                | 'BooleanList'
                | 'MultilingualCollection';
            value: string | number | unknown;
        };
    };
};

export type ProductCategoryQueryResponseType = {
    totalNumberOfResults: number;
    categories: ProductCategoryQueryItem[];
};

export type RelewiseRangeType = {
    lowerBoundInclusive?: number;
    upperBoundInclusive?: number;
};

export type RelewiseRangesType = {
    lowerBoundInclusive?: number;
    upperBoundExclusive?: number;
};

export type FacetsBodyType = {
    $type: string;
    key?: string;
    collectionFilterType?: string;
    categorySelectionStrategy?: string;
    priceSelectionStrategy?: string;
    dataSelectionStrategy?: string;
    field: string;
    selected?: (string | number | RelewiseRangesType)[] | RelewiseRangeType;
    available?: (string | number | RelewiseRangesType)[] | RelewiseRangeType;
    expandedRangeSize?: number;
    predefinedRanges?: RelewiseRangesType[];
    settings?: {
        alwaysIncludeSelectedInAvailable?: boolean;
    };
};

export enum RelewiseProductSearchFacetTypes {
    PRODUCT_CATEGORY = 'Relewise.Client.DataTypes.Search.Facets.Queries.CategoryFacet, Relewise.Client',
    PRODUCT_DATA_STRING = 'Relewise.Client.DataTypes.Search.Facets.Queries.ProductDataStringValueFacet, Relewise.Client',
    PRODUCT_DATA_BOOLEAN = 'Relewise.Client.DataTypes.Search.Facets.Queries.ProductDataBooleanValueFacet, Relewise.Client',
    PRODUCT_DATA_DOUBLE = 'Relewise.Client.DataTypes.Search.Facets.Queries.ProductDataDoubleValueFacet, Relewise.Client',
    PRODUCT_DATA_DOUBLE_RANGE = 'Relewise.Client.DataTypes.Search.Facets.Queries.ProductDataDoubleRangeFacet, Relewise.Client',
    PRODUCT_DATA_DOUBLE_RANGES = 'Relewise.Client.DataTypes.Search.Facets.Queries.ProductDataDoubleRangesFacet, Relewise.Client',
    PRICE_RANGE = 'Relewise.Client.DataTypes.Search.Facets.Queries.PriceRangeFacet',
    PRICE_RANGES = 'Relewise.Client.DataTypes.Search.Facets.Queries.PriceRangesFacet, Relewise.Client',
    BRAND = 'Relewise.Client.DataTypes.Search.Facets.Queries.BrandFacet, Relewise.Client',
    CONTENT_DATA_STRING = 'Relewise.Client.DataTypes.Search.Facets.Queries.ContentDataStringValueFacet, Relewise.Client',
}

export enum RelewiseContentDataTagsTypes {
    L1Category = 'L1 Category',
    ContentType = 'Content Type',
    L2Category = 'L2 Category',
    Sector = 'Sector',
    Theme = 'Theme',
}

export const sortOptions: SortType[] = [
    {
        sort: 'asc',
        attribute: undefined,
        label: 'By Relevance',
    },
    {
        sort: 'asc',
        attribute: 'DisplayName',
        label: 'By Name (Asc)',
    },
    {
        sort: 'desc',
        attribute: 'DisplayName',
        label: 'By Name (Desc)',
    },
];
